.App {
  text-align: center;
}
.uploadBtn button {
  display: flex;
  justify-content: center;
  background-color: #f3f3f3;
  border: 2px dashed #ddd;
  width: 100%;
  max-width: 600px;
  min-height: 200px;
  border-radius: 6px;
  margin: auto;
  align-items: center;
}
.fileName {
  display: flex;
  justify-content: space-between;
  background-color: cornflowerblue;
  padding: 5px 10px;
  border-radius: 6px;
  margin-top: 10px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  color: #fff;
}
.uploadFile {
  background-color: #337533;
  color: #fff;
  margin-top: 20px;
  width: 200px;
  font-size: 20px;
  font-weight: 600;
  padding: 10px 0px 10px 5px;
  border-radius: 6px;
  text-transform: uppercase;
}
.percentageUpload {
  margin-top: 150px;
  margin-bottom: 150px;
  min-width: 100px;
}
.percentageUpload .MuiCircularProgress-root {
  width: 100px !important;
  margin-right: 50px;
}
.topnav {
  overflow: hidden;
  background-color: #e9e9e9;
  max-height: 50px;
  margin-bottom: 40px;
}

.topnav h1 {
  float: left;
  display: block;
  color: #121213;
  text-align: center;
  text-decoration: none;
  font-size: 17px;
  padding: 1px 0px 0px 15px;
}
.topnav .login-container {
  float: right;
}
.topnav .login-container .MuiButton-text {
  padding: 13px 18px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
